<template lang="pug">
  div
    el-row
      el-col
        h3.title F. Cancelaciones - Banco
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Fecha de cancelación:")
            date-input(:inputModel="search" modelKey="dateRange" type="daterange")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Datos actualizados:")
            select-input(:options="updatedBankData" :attribute="search" modelKey="updatedBankData")
          el-form-item(label="Método devolución:")
            select-input(
              :options="refundMethods"
              :attribute="search"
              modelKey="refundMethods"
              :multiple="true"
            )
          el-form-item(label="Estado:")
            select-input(
              :options="cancelationStates()"
              :attribute="search"
              modelKey="cancelationStates"
              :multiple="true"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchCancellations()"
            ) FILTRAR
          el-form-item.filters__buttons-wrapper
            el-dropdown(@command="exportTable" style="margin: 0 12px 0 12px")
              el-button(
                type="success"
                Dropdown
              ) EXPORTAR TABLA
                i(class="el-icon-arrow-down el-icon--right")
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(command="all") Exportar todo
          el-form-item.filters__buttons-wrapper
            el-button(
              type="success"
              icon="el-icon-download"
              @click.prevent="confirmExport()"
            ) EXPORTAR NÓMINA
          el-form-item.filters__buttons-wrapper
            el-button(
              type="warning"
              icon="el-icon-download"
              @click.prevent="handleUploadDialog()"
            ) SUBIR RESPUESTA NÓMINA
          el-form-item.filters__buttons-wrapper
            el-button(
              type="warning"
              icon="el-icon-s-finance"
              @click.prevent="handleRefundTransbank()"
            ) REEMBOLSAR TBK
          el-form-item.filters__buttons-wrapper
            el-button(
              type="warning"
              icon="el-icon-s-finance"
              @click.prevent="handleRefundOneclick()"
            ) REEMBOLSAR ONECLICK
    el-row
      el-col
        stats-table(
          :data="stats"
          :rowSize="20"
          :colSize="8"
        )
    el-row(:key="renderTable")
      el-col
        report-table(
          v-loading="loading"
          :headers="headers"
          :height="600"
          :paginated="true"
          :pageSize="20"
          :rowModel="'infinite'"
          :blockSize="20"
          :blocksInCache="20"
          :datasourceRequests="1"
          @updateTable="updateTable"
        )
    el-dialog(
      title="Subir archivo del Banco"
      :visible.sync="uploadDialog.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-form(:inline="true" :model="uploadDialog" :rules="uploadFormRules" ref="form")
        el-row
          el-col
            el-form-item(label="Selecciona un archivo:" prop="file")
              el-upload(
                v-model="uploadDialog.file"
                action=""
                accept=".xls"
                :limit="1"
                :file-list="uploadDialog.file"
                :auto-upload="false"
                :on-change="handleChangeFile"
              )
                el-button(slot="trigger" size="small" type="primary") Buscar
        el-row
          el-col
            el-form-item(label="Fecha de pago:" prop="date")
              date-input(:inputModel="uploadDialog" modelKey="date" type="date")
        el-row.inline-form__actions
          el-button(@click="closeUploadDialog()") Cancelar
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="uploadBankFile()"
            :loading="loading"
          ) Subir
    el-dialog(
      :title="this.summaryDialog.title"
      :visible.sync="summaryDialog.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="60%")
      el-row(:gutter="12" style="margin: 25px 0")
        el-col(:span="8")
          el-card
            el-result(icon="info")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(label="Total Cancelaciones") {{ this.summaryDialog.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ this.summaryDialog.total }}
        el-col(:span="8")
          el-card
            el-result(icon="success")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(
                    label="Cancelaciones Pagadas") {{ this.summaryDialog.refund.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ this.summaryDialog.refund.total }}
        el-col(:span="8")
          el-card
            el-result(icon="error")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(
                    label="Cancelaciones Rechazadas") {{ this.summaryDialog.reject.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ this.summaryDialog.reject.total }}
      el-row(:gutter="12" style="margin: 25px 0")
        el-alert(
          v-if="summaryError.visible"
          :description="summaryError.description"
          type="error"
          :closable="false")
      el-row.inline-form__actions
        el-button(
          type="primary"
          @click="closeDialog()"
        ) Aceptar
    el-dialog(
      title="¡Cuidado!"
      :visible.sync="warningDateImport.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-row(:gutter="12")
        el-col
          el-result(icon="warning" :title="this.warningDateImport.title"
          :subTitle="this.warningDateImport.message")
      el-row.inline-form__actions
        el-button(@click="warningDateImport.visible = false") No
        el-button(
          type="warning"
          @click="warningDateImport.visible = false"
          @click.prevent="handleConfirmUpload()"
        ) Si
    el-dialog(
      title="¡Cuidado!"
      :visible.sync="warningFilenameImport.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-row(:gutter="12")
        el-col
          el-result(icon="warning" :title="this.warningFilenameImport.title"
          :subTitle="this.warningFilenameImport.message")
      el-row.inline-form__actions
        el-button(
          @click="warningFilenameImport.visible = false"
          @click.prevent="uploadDialog.file = []"
        ) No
        el-button(
          type="warning"
          @click="warningFilenameImport.visible = false"
          @click.prevent="confirmUpload = true"
        ) Si
    el-dialog(
      :title="changeStateDialog.title"
      :visible.sync="changeStateDialog.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-form(:inline="true" :model="changeStateDialog")
        el-row(:gutter="24")
          el-col(:span="12")
            el-form-item(label="Estado actual:") {{ this.changeStateDialog.currentState }}
          el-col(:span="12")
            el-form-item(label="Estado nuevo:")
              select-input(
                :changeMethod="this.handleChange"
                :options="changeStateDialog.states"
                :attribute="changeStateDialog"
                modelKey="state"
              )
        el-row(v-show="changeStateDialog.excludedInput")
          el-form-item(label="Motivo de exclusión")
            el-input(v-model="changeStateDialog.excludeReason" type="textarea" :rows="4"
            :cols="35")
        el-row(v-show="changeStateDialog.refundedInput")
          el-form-item(label="Número de transacción")
            el-input(v-model="changeStateDialog.transactionNumber" type="text")
      el-row.inline-form__actions
        el-button(
          @click="closeChangeStateDialog"
        ) Cancelar
        el-button(
          type="warning"
          @click="confirmChangeState"
        ) Aceptar
    el-dialog(
      :visible.sync="confirmChangeStateDialog.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="40%"
      :show-close="false")
      el-row(:gutter="12")
        el-col
          el-result(icon="warning" :title="this.confirmChangeStateDialog.title"
          style="padding: 0px")
      el-steps(:space="200" simple style="margin: 50px 0; padding: 20px 10px")
        el-step(status="finish" :title="this.confirmChangeStateDialog.firstStepTitle")
        el-step(status="success" :title="this.confirmChangeStateDialog.lastStepTitle")
      el-row.inline-form__actions
        el-button(
          @click="discardChangeState"
        ) Descartar
        el-button(
          type="warning"
          @click="changeState"
        ) Confirmar
    el-dialog(
      :title="historicDetail.title"
      :visible.sync="historicDetail.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="60%")
      historic-details(
        :historicalRecords="this.historicDetail.historicalRecords"
      )
    refund-modal(
      title="Se ha realizado un proceso de reembolso Transbank"
      :visible.sync="dialogTransbank.visible"
      :loading="loading"
      :refundModal="refundModalTransbank"
      :responseModal="responseModalTransbank"
      :dateRange="search.dateRange"
      :refundData="toRefundTransbank"
      @confirm="refundTransbank"
      @close="closeDialogTransbank"
    )
    refund-modal(
      title="Se ha realizado un proceso de reembolso Oneclick"
      :visible.sync="dialogOneclick.visible"
      :loading="loading"
      :refundModal="refundModalOneclick"
      :responseModal="responseModalOneclick"
      :dateRange="search.dateRange"
      :refundData="toRefundOneclick"
      @confirm="refundOneclick"
      @close="closeDialogOneclick"
    )
</template>

<script>
import ReportTable from '@/components/tables/ReportTable.vue';
import RefundModal from '@/components/ui/RefundModal.vue';
import StatsTable from '@/components/tables/StatsTable.vue';
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';
import HistoricDetails from '@/components/reimburse_users/f_cancellations/historic_details/HistoricDetails.vue';
import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

// eslint-disable-next-line import/no-cycle
import DataService from '@/services/dataService';

// eslint-disable-next-line import/no-cycle
import handleDownload from '@/utils/handleDownload';
import DateService from '@/services/dateService';

export default {
  name: 'BankView',
  components: {
    ReportTable, StatsTable, SelectInput, DateInput, HistoricDetails, RefundModal,
  },
  data() {
    return {
      headers: [
        {
          headerName: 'Estado',
          field: 'state',
          cellRenderer: 'agGroupCellRenderer',
          onCellClicked: this.handleChangeState,
        },
        { headerName: 'Método devolución', field: 'method' },
        {
          headerName: 'ID Cancelación',
          field: 'id_k',
          cellRenderer: 'agGroupCellRenderer',
          onCellClicked: this.handleHistoricDetails,
          filterType: 'text',
        },
        { headerName: 'Fecha de cancelación', field: 'canceled_at' },
        {
          headerName: 'Método de pago', field: 'payment_method', filterType: 'selector', selectorOptions: this.paymentTypeSelectOptions(), width: 170,
        },
        { headerName: 'Código Autorización', field: 'tbk_authorization_code' },
        {
          headerName: 'Operador de bus', field: 'bus_operator', filterType: 'text',
        },
        { headerName: 'Token compra', field: 'token', filterType: 'text' },
        { headerName: 'N° boleto', field: 'number', filterType: 'text' },
        { headerName: 'Nombre', field: 'fullname', filterType: 'text' },
        { headerName: 'Rut cuenta', field: 'bank_account_rut', filterType: 'text' },
        { headerName: 'Correo', field: 'email', filterType: 'text' },
        { headerName: 'Banco', field: 'bank_name', filterType: 'text' },
        { headerName: 'Tipo de cuenta', field: 'bank_account', filterType: 'text' },
        { headerName: 'N° cuenta', field: 'bank_account_number', filterType: 'text' },
        { headerName: 'Datos actualizados', field: 'updated_data' },
        { headerName: 'Nombre nomina', field: 'payment_file_name', filterType: 'text' },
        { headerName: 'ID de reclamo', field: 'complaint_number', filterType: 'text' },
        { headerName: 'Reembolsado en (Fecha)', field: 'refunded_at', filterType: 'range' },
        { headerName: 'Precio boleto ($M. Funcional)', field: 'final_price_ioc', filterType: 'range' },
        { headerName: 'Reembolso boleto ($M. Funcional)', field: 'devolution_ioc', filterType: 'range' },
        { headerName: 'Descuento OP ($M. Funcional)', field: 'operator_discount', filterType: 'range' },
        { headerName: 'Descuento CT ($M. Funcional)', field: 'offset_discount', filterType: 'range' },
        { headerName: 'Descuento a Recorrido', field: 'recorrido_discount', filterType: 'range' },
      ],
      stats: [
        { title: 'Cancelaciones Banco', total: 0 },
        { title: 'CLP', total: currencyFormatter(0) },
      ],
      search: {
        country: 'CL',
        refundMethods: [],
        updatedBankData: 'all',
        cancelationStates: [],
        dateRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        filters: {},
      },
      searchCancellations: false,
      loading: false,
      currentFileName: '',
      confirmUpload: false,
      uploadDialog: {
        visible: false,
        file: [],
        date: DateService.getDate(),
      },
      summaryDialog: {
        visible: false,
        title: '',
        total: 0,
        count: 0,
        refund: {
          count: 0,
          total: 0,
        },
        reject: {
          count: 0,
          total: 0,
        },
      },
      summaryError: {
        visible: false,
        description: 'Ha ocurrido un error en uno de los lotes de cancelaciones, informe al equipo Back Office',
      },
      warningDateImport: {
        visible: false,
        message: '',
        title: '¿Estás seguro que quieres importar de nuevo?',
      },
      warningFilenameImport: {
        visible: false,
        message: '',
        title: '¿Estás seguro que quieres importar este archivo?',
      },
      dialogTransbank: { visible: false },
      dialogOneclick: { visible: false },
      toRefundTransbank: {
        count: 0,
        total: 0,
        response: {
          count: 0,
          total: 0,
        },
        refund: {
          count: 0,
          total: 0,
        },
        reject: {
          count: 0,
          total: 0,
        },
      },
      toRefundOneclick: {
        count: 0,
        total: 0,
        response: {
          count: 0,
          total: 0,
        },
        refund: {
          count: 0,
          total: 0,
        },
        reject: {
          count: 0,
          total: 0,
        },
      },
      refundModalTransbank: true,
      refundModalOneclick: true,
      responseModalTransbank: false,
      responseModalOneclick: false,
      changeStateDialog: {
        visible: false,
        id: '',
        title: '',
        currentState: '',
        excludeReason: '',
        transactionNumber: '',
        state: '',
        states: [{ key: 'refunded', value: 'Devuelto' }, { key: 'excluded', value: 'Excluido' }],
        refundedInput: false,
        excludedInput: false,
      },
      confirmChangeStateDialog: {
        visible: false,
        title: '',
        firstStepTitle: '',
        lastStepTitle: '',
      },
      historicDetail: {
        visible: false,
        title: '',
        historicalRecords: [],
      },
      renderTable: 0,
      setSummary: true,
      scrollId: null,
      paramsApi: null,
      data: [],
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setCancellations();
            const lastRow = this.data.data_size;
            rowsParams.successCallback(this.data.tickets, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };
        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  channels: {
    JobProgressChannel: {
      connected() {
        console.log('Connected to JobProgressChannel');
      },
      rejected() { },
      received(data) {
        console.log('Received:', data);
      },
      disconnected() { },
    },
  },
  methods: {
    async setCancellations() {
      this.searchCancellations = false;
      const response = await FinanceApi.get_bank_cancellations(this.scrollId, this.search);

      this.scrollId = response.body.data.scroll_id;
      if (this.setSummary) { this.summary(response); }
      if (response.body.data.tickets.length > 0) { this.searchCancellations = true; }

      if (Object.keys(response.body.data.stats).length > 0) {
        this.toRefundTransbank.count = response.body.data.stats.pendings_transbank.count;
        this.toRefundOneclick.count = response.body.data.stats.pendings_oneclick.count;
        const { country } = this.search;
        this.toRefundTransbank.total = currencyFormatter(
          response.body.data.stats.pendings_transbank.total, country,
        );
        this.toRefundOneclick.total = currencyFormatter(
          response.body.data.stats.pendings_oneclick.total, country,
        );
      }

      this.updateColumnDefs();

      return response.body.data;
    },
    async fetchCancellations() {
      this.renderTable += 1;
      this.setSummary = true;
      this.scrollId = null;
    },
    summary(response) {
      this.stats = this.formatStats(response.body.data.stats);
      this.setSummary = false;
    },
    cleanSearchFilter() {
      this.scrollId = null;
      this.setSummary = true;
    },
    updateColumnDefs() {
      if (this.search.country === 'PE') {
        if (!this.headers.some((header) => header.field === 'peru_bank_transfer')) {
          this.headers.splice(14, 0, { headerName: 'CCI', field: 'peru_bank_transfer' });
        }
      } else {
        this.headers = this.headers.filter((header) => header.field !== 'peru_bank_transfer');
      }
    },
    handleHistoricDetails(params) {
      this.historicDetail.historicalRecords = [];
      const { data } = params;
      this.historicDetail.visible = true;
      this.historicDetail.title = `Cancelación ${data.id}`;
      const currentDate = new Date();
      data.historical_records.sort((a, b) => {
        const dateItemOne = new Date(a.state_at);
        const dateItemTwo = new Date(b.state_at);
        return Math.abs(dateItemTwo - currentDate) - Math.abs(dateItemOne - currentDate);
      });
      this.historicDetail.historicalRecords = data.historical_records.reverse();
    },
    handleChangeState(params) {
      const { data } = params;
      if (data.state === 'Pendiente') {
        this.changeStateDialog.visible = true;
        this.changeStateDialog.id = data.id;
        this.changeStateDialog.title = `Cambio de estado manual de Cancelación ${data.id}`;
        this.changeStateDialog.currentState = data.state;
      }
    },
    handleChange(state) {
      if (state === 'refunded') {
        this.changeStateDialog.refundedInput = true;
        this.changeStateDialog.excludedInput = false;
        this.changeStateDialog.transactionNumber = '';
      } else {
        this.changeStateDialog.excludedInput = true;
        this.changeStateDialog.refundedInput = false;
        this.changeStateDialog.excludeReason = '';
      }
    },
    closeChangeStateDialog() {
      this.changeStateDialog.visible = false;
      this.changeStateDialog.refundedInput = false;
      this.changeStateDialog.excludedInput = false;
      this.changeStateDialog.state = '';
      this.changeStateDialog.excludeReason = '';
      this.changeStateDialog.transactionNumber = '';
    },
    confirmChangeState() {
      if (this.changeStateDialog.state.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar un estado' });
        return;
      }
      if (this.changeStateDialog.refundedInput
          && this.changeStateDialog.transactionNumber.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe ingresar un numero de transacción' });
        return;
      }
      if (this.changeStateDialog.excludedInput
          && this.changeStateDialog.excludeReason.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe ingresar un motivo de exclusión' });
        return;
      }
      this.changeStateDialog.visible = false;
      this.confirmChangeStateDialog.visible = true;
      this.confirmChangeStateDialog.title = `¿Estás seguro que deseas cambiar el estado de la cancelación ${this.changeStateDialog.id}?`;
      this.confirmChangeStateDialog.firstStepTitle = `Estado actual: ${this.changeStateDialog.currentState}`;
      const newState = this.changeStateDialog.state === 'refunded' ? 'Devuelto' : 'Excluido';
      this.confirmChangeStateDialog.lastStepTitle = `Estado nuevo: ${newState}`;
    },
    discardChangeState() {
      this.confirmChangeStateDialog.visible = false;
      this.changeStateDialog.visible = true;
    },
    changeState() {
      this.loading = true;
      FinanceApi.manual_change_state(this.changeStateDialog).then((data) => {
        this.fetchCancellationsDelay();
        this.closeChangeStateDialog();
        if (data.body) {
          this.$notify({ message: 'Cambios guardados exitosamente', type: 'success' });
        } else {
          this.$notify({ message: 'No se logró actualizar la cancelación', type: 'error' });
        }
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    confirmExport() {
      this.loading = true;
      if (this.searchCancellations) {
        this.$confirm('Al exportar nómina vas a cambiar el estado de Pendiente a Procesando de todas las cancelaciones filtradas', '¡Cuidado!', {
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cerrar',
          type: 'warning',
        }).then(() => {
          this.exportCancellations();
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay resultados para exportar' });
        this.loading = false;
      }
    },
    exportCancellations() {
      this.loading = true;
      FinanceApi.get_bank_cancellations(this.scrollId, this.search, true, 'text').then((res) => {
        this.fetchCancellationsDelay();
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    exportTable() {
      if (this.searchCancellations) {
        FinanceApi.get_bank_cancellations(this.scrollId, this.search, true, 'xlsx').then(() => {
          this.$message({ type: 'success', message: 'Reporte enviado' });
        }).catch((exception) => {
          this.$notify.error({ title: 'Error', message: exception.body.message });
        });
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay resultados para exportar' });
      }
    },
    formatStats(stats) {
      let titleValue = '';
      switch (this.search.country) {
        case 'CL': titleValue = 'CLP'; break;
        case 'PE': titleValue = 'PEN'; break;
        case 'MX': titleValue = 'MXN'; break;
        default:
      }

      return [
        { title: 'CANCELACIONES', total: stats.count },
        { title: titleValue, total: currencyFormatter(stats.total_price, this.search.country) },
      ];
    },
    cancelationStates() {
      return DataService.CancelationStates('bank');
    },
    handleUploadDialog() {
      this.loading = true;
      FinanceApi.historic_imports('date', 'bank').then((data) => {
        if (data.body.invalid) {
          this.warningDateImport.message = `Hoy ${data.body.date} ya fue importado por ${data.body.user} a las ${data.body.hour} Hrs. el archivo ${data.body.filename}`;
          this.warningDateImport.visible = true;
        } else {
          this.uploadDialog.visible = true;
        }
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });

        this.loading = false;
      });
    },
    closeUploadDialog() {
      this.uploadDialog.visible = false;
      this.confirmUpload = false;
      this.uploadDialog.file = [];
    },
    handleChangeFile(file) {
      this.currentFileName = file.name;
      this.uploadDialog.file = [];
      this.uploadDialog.file.push(file.raw);
    },
    uploadFormRules() {
      return {
        date: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
        file: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
      };
    },
    uploadBankFile() {
      this.loading = true;

      if (this.uploadDialog.file.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar un archivo' });
        return;
      }
      if (this.uploadDialog.date === null) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar una fecha' });
        return;
      }

      FinanceApi.historic_imports('filename', 'bank', this.uploadDialog.file[0].name).then((validData) => {
        if (validData.body.invalid && !this.confirmUpload) {
          this.warningFilenameImport.message = `El archivo ${validData.body.filename} ya fue importado por ${validData.body.user} el ${validData.body.date} a las ${validData.body.hour} Hrs.`;
          this.warningFilenameImport.visible = true;
          this.loading = false;
        } else {
          const formData = new FormData();

          formData.append('date', this.uploadDialog.date);
          formData.append('file', this.uploadDialog.file[0]);

          FinanceApi.import_bank(formData).then((data) => {
            this.loading = true;
            this.summaryError.visible = false;
            this.fetchCancellationsDelay();
            this.summaryDialog.title = this.titleSummaryDialog(data.body.status, data.body.message);
            const { country } = this.search;
            const totalAmount = data.body.refunded.total + data.body.rejected.total;
            this.summaryDialog.count = data.body.total;
            this.summaryDialog.total = currencyFormatter(totalAmount, country);
            this.summaryDialog.refund.count = data.body.refunded.quantity;
            this.summaryDialog.refund.total = currencyFormatter(data.body.refunded.total, country);
            this.summaryDialog.reject.count = data.body.rejected.quantity;
            this.summaryDialog.reject.total = currencyFormatter(data.body.rejected.total, country);

            this.uploadDialog.visible = false;
            this.summaryDialog.visible = true;
            this.cleanFormFields();

            this.confirmUpload = false;
          }).catch((exception) => {
            this.$notify.error({ title: 'Error', message: exception.body.message });

            this.loading = false;
          });
        }
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });

        this.loading = false;
      });
    },
    handleConfirmUpload() {
      this.uploadDialog.visible = true;
    },
    titleSummaryDialog(status, message = '') {
      if (status === 'error') {
        let formatMessage = `No se logró importar el archivo ${this.currentFileName}`;
        if (message !== '') {
          formatMessage += ` (${message})`;
        }
        return formatMessage;
      }
      if (status === 'partial') {
        this.summaryError.visible = true;
        return `Se ha importado parcialmente el archivo ${this.currentFileName}`;
      }

      return `Se ha importado correctamente el archivo ${this.currentFileName}`;
    },
    cleanFormFields() {
      this.uploadDialog.file = [];
      this.uploadDialog.date = DateService.getDate();
    },
    handleRefundTransbank() {
      if (this.toRefundTransbank.count !== 0) {
        this.refundModalTransbank = true;
        this.responseModalTransbank = false;
        this.dialogTransbank.visible = true;
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay cancelaciones con método de devolución Transbank pendientes para reembolsar' });
      }
    },
    handleRefundOneclick() {
      if (this.toRefundOneclick.count !== 0) {
        this.refundModalOneclick = true;
        this.responseModalOneclick = false;
        this.dialogOneclick.visible = true;
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay cancelaciones con método de devolución Oneclick pendientes para reembolsar' });
      }
    },
    refundTransbank() {
      this.loading = true;
      FinanceApi.refund_webpay_cancellations(this.search, 'via_tbk_devolution').then((data) => {
        this.fetchCancellationsDelay();
        const { country } = this.search;
        this.toRefundTransbank.response.count = (
          data.body.refunded_quantity
          + data.body.rejected_quantity
        );
        this.toRefundTransbank.response.total = currencyFormatter(
          data.body.refunded_total + data.body.rejected_total, country,
        );
        this.toRefundTransbank.refund.count = data.body.refunded_quantity;
        this.toRefundTransbank.refund.total = currencyFormatter(data.body.refunded_total, country);
        this.toRefundTransbank.reject.count = data.body.rejected_quantity;
        this.toRefundTransbank.reject.total = currencyFormatter(data.body.rejected_total, country);
        this.refundModalTransbank = false;
        this.responseModalTransbank = true;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    refundOneclick() {
      this.loading = true;
      FinanceApi.refund_webpay_cancellations(this.search, 'via_oneclick').then((data) => {
        this.fetchCancellationsDelay();
        const { country } = this.search;
        this.toRefundOneclick.response.count = (
          data.body.refunded_quantity
          + data.body.rejected_quantity
        );
        this.toRefundOneclick.response.total = currencyFormatter(
          data.body.refunded_total + data.body.rejected_total, country,
        );
        this.toRefundOneclick.refund.count = data.body.refunded_quantity;
        this.toRefundOneclick.refund.total = currencyFormatter(data.body.refunded_total, country);
        this.toRefundOneclick.reject.count = data.body.rejected_quantity;
        this.toRefundOneclick.reject.total = currencyFormatter(data.body.rejected_total, country);
        this.refundModalOneclick = false;
        this.responseModalOneclick = true;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    closeDialog() {
      this.summaryDialog.visible = false;
    },
    closeDialogTransbank() {
      this.dialogTransbank.visible = false;
    },
    closeDialogOneclick() {
      this.dialogOneclick.visible = false;
    },
    fetchCancellationsDelay() {
      setTimeout(() => {
        this.fetchCancellations();
        this.confirmChangeStateDialog.visible = false;
        this.loading = false;
      }, 15000);
    },
    paymentTypeSelectOptions() {
      return [
        { key: 'Todos', value: '' },
        { key: 'WebPay-Crédito', value: 'WebPay-Crédito' },
        { key: 'WebPay-Débito', value: 'WebPay-Débito' },
        { key: 'OneClick-Crédito', value: 'OneClick-Crédito' },
        { key: 'Fintoc', value: 'Fintoc' },
        { key: 'Venta Terra', value: 'Venta Terra' },
        { key: '100% Cupones', value: '100% Cupones' },
      ];
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;
      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length
        && Object.keys(this.search.filters).length === 0) {
          this.search.filters = {};
          this.cleanSearchFilter();
        }
        this.search.filters = {};
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (filterValue.type === 'from' || filterValue.type === 'to') {
            if (this.search.filters[key] === undefined) {
              this.search.filters[key] = [];
            }
            const date = DateService.convertToDate(key, filterValue.filter);
            if (filterValue.type === 'from' && this.search.filters[key][0] !== date) {
              this.search.filters[key][0] = date;
              this.cleanSearchFilter();
            }
            if (filterValue.type === 'to' && this.search.filters[key][1] !== date) {
              if (filterValue.filter === '-1') {
                this.search.filters[key].pop();
                this.cleanSearchFilter();
              } else {
                this.search.filters[key][1] = date;
                if (this.search.filters[key][0] === undefined) { this.search.filters[key][0] = '0'; }
                this.cleanSearchFilter();
              }
            }
            this.cleanSearchFilter();
          } else if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
            this.cleanSearchFilter();
          } else if (this.search.filters.length !== filterModel.length) {
            this.cleanSearchFilter();
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.search.filters = {};
        this.cleanSearchFilter();
      }
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    updatedBankData() {
      return [
        { key: 'updated', value: 'Si' },
        { key: 'outdated', value: 'No' },
        { key: 'all', value: 'Todos' },
      ];
    },
    refundMethods() {
      return DataService.TicketCancellationRefundMethods('bank');
    },
  },
  mounted() {
    this.$cable.subscribe({ channel: 'JobProgressChannel' });
  },
  destroyed() {
    this.$cable.unsubscribe({ channel: 'JobProgressChannel' });
  },
};
</script>
