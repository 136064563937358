<template lang="pug">
el-dialog(
  :title="title"
  :visible.sync="localVisible"
  :before-close="onClose"
  v-loading="loading"
  width="60%"
)
  el-row
    el-col
      h4(v-show="refundModal") ¿Está seguro de que quiere pagar?
      el-row(v-show="responseModal")
        el-col(:span="3") Rango de fechas:
        el-col(:span="3" :offset="0") Desde
        el-col(:span="3") Hasta
      el-row(v-show="responseModal")
        el-col(:span="3" :offset="3") <span v-text="dateRange[0]"></span>
        el-col(:span="3") <span v-text="dateRange[1]"></span>
      el-row(v-show="refundModal")
        el-col(:span="8" :offset="8") Desde
        el-col(:span="8") Hasta
      el-row(v-show="refundModal")
        el-col(:span="8") Rango de fechas:
        el-col(:span="8") <span v-text="dateRange[0]"></span>
        el-col(:span="8") <span v-text="dateRange[1]"></span>
      el-row(v-show="refundModal")
        el-col(:span="8") Cantidad de cancelaciones:
        el-col(:span="8") <span v-text="refundData.count"></span>
      el-row(v-show="refundModal")
        el-col(:span="8") Monto total:
        el-col(:span="8") <span v-text="refundData.total"></span>
      el-row(:gutter="12" v-show="responseModal" style="margin: 25px 0")
        el-col(:span="8")
          el-card
            el-result(icon="info")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(label="Total Cancelaciones") {{ refundData.response.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ refundData.response.total }}
        el-col(:span="8")
          el-card
            el-result(icon="success")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(label="Cancelaciones Pagadas") {{ refundData.refund.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ refundData.refund.total }}
        el-col(:span="8")
          el-card
            el-result(icon="error")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(
                    label="Cancelaciones Rechazadas") {{ refundData.reject.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ refundData.reject.total }}
  el-row.inline-form__actions
    el-button(type="primary" @click.prevent="onConfirm" v-show="refundModal") Aceptar
    el-button(type="danger" @click="onClose" v-show="refundModal") Cancelar
    el-button(type="primary" @click="onClose" v-show="responseModal") Aceptar
</template>

<script>
export default {
  name: 'RefundModal',
  props: {
    title: String,
    visible: Boolean,
    loading: Boolean,
    refundModal: Boolean,
    responseModal: Boolean,
    dateRange: Array,
    refundData: Object,
  },
  data() {
    return {
      localVisible: this.visible,
    };
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
    },
  },
  methods: {
    onConfirm() {
      this.$emit('confirm');
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.inline-form__actions {
  margin-top: 20px;
}
</style>
