<template lang="pug">
  el-date-picker(
    v-model="inputModel[modelKey]"
    :type="type"
    :value-format="format"
    :picker-options="pickerOptions"
    :disabled="inputModel.disabled"
  )
</template>

<script>
export default {
  name: 'DateInput',
  props: {
    inputModel: { required: true },
    modelKey: { required: false, default: 'date' },
    type: { required: false, default: 'date' },
    format: { required: false, default: 'yyyy-MM-dd' },
    options: { required: false },
    disabled: { required: false, default: false },
  },
  computed: {
    pickerOptions() {
      return { firstDayOfWeek: 1, ...this.options };
    },
  },
};
</script>
