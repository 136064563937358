import constants from '@/constants/Url.constants';
import Vue from 'vue';
import Router from 'vue-router';
import Resource from 'vue-resource';

import store from '@/store';

import Login from '@/components/Login.vue';
import Logout from '@/components/Logout.vue';
import Password from '@/components/Password.vue';
import Home from '@/components/Home.vue';
import FullLayout from '@/components/layout/FullLayout.vue';
import MainContent from '@/components/layout/MainContent.vue';

import BankTransfers from '@/components/payment_operators/transfers/BankTransfers.vue';
import PendingTransfersBice from '@/components/payment_operators/transfers/PendingTransfersBice.vue';
import PendingTransfersScotiabank from '@/components/payment_operators/transfers/PendingTransfersScotiabank.vue';

import Conciliations from '@/components/payment_operators/conciliations/Conciliations.vue';
import Conciliation from '@/components/payment_operators/conciliations/Conciliation.vue';
import ReaendedConciliations from '@/components/payment_operators/conciliations/ResendedConciliations.vue';

import DiscountsHistory from '@/components/payment_operators/discounts/History.vue';
import DiscountsClosed from '@/components/payment_operators/discounts/DiscountsClosed.vue';
import DiscountsApproved from '@/components/payment_operators/discounts/DiscountsApproved.vue';

import ComplaintsReport from '@/components/reimburse_users/ComplaintsReport.vue';
import DevolutionCoupons from '@/components/reimburse_users/DevolutionCoupons.vue';

import PaypalView from '@/components/reimburse_users/f_cancellations/PaypalView.vue';
import GeneralView from '@/components/reimburse_users/f_cancellations/GeneralView.vue';
import BankView from '@/components/reimburse_users/f_cancellations/BankView.vue';

import Sales from '@/components/control/analytics/sales/Sales.vue';
import TbkTransactions from '@/components/control/analytics/tbk/Transactions.vue';
import AutomaticCancellations from '@/components/control/analytics/AutomaticCancellations.vue';
import InsuranceReport from '@/components/control/analytics/InsuranceReport.vue';

import MasterSales from '@/components/control/master/Sales.vue';
import MasterIncomes from '@/components/control/master/Incomes.vue';

import MonthlyDeferredSales from '@/components/control/deferred_sales/MonthlyDeferredSales.vue';
import DailyDeferredSales from '@/components/control/deferred_sales/DailyDeferredSales.vue';

import Groups from '@/components/settings/Groups.vue';
import Operators from '@/components/settings/Operators.vue';
import Agencies from '@/components/settings/Agencies.vue';
import Holidays from '@/components/settings/Holidays.vue';
import GeneralSettings from '@/components/settings/GeneralSettings.vue';
import UserManagament from '@/components/settings/UserManagament.vue';

import RecorridoFee from '@/components/recorrido_fee/RecorridoFee.vue';

import ActivityHistory from '@/components/control/activity_history/ActivityHistory.vue';

import BillCredit from '@/components/control/netsuite/BillCredit.vue';

import * as fn from '@/utils/functions';

Vue.use(Router);
Vue.use(Resource);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/reset_password/rpt=:rpt',
      name: 'password',
      component: Password,
      meta: {
        title: 'Password',
        requiresAuth: false,
        level: 0,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'recorrido-icon', level: 1,
      },
    },
    {
      path: '/main-payment-operators',
      name: 'main_payment_operators',
      component: FullLayout,
      meta: {
        title: 'Pago Operadores', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'cashier', level: 1,
      },
      children: [
        {
          path: '/main-payment-operators/main-transfers',
          name: 'main_transfers',
          component: MainContent,
          meta: {
            title: 'Transferencias', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'accounting', level: 2,
          },
          children: [
            {
              path: '/main-payment-operators/main-transfers/pending-transfers-bice',
              name: 'pending-transfers-bice',
              component: PendingTransfersBice,
              meta: { title: 'TEF Pendientes BICE', auth: true, level: 3 },
            },
            {
              path: '/main-payment-operators/main-transfers/pending-transfers-scotiabank',
              name: 'pending-transfers-scotiabank',
              component: PendingTransfersScotiabank,
              meta: { title: 'TEF Pendientes Scotiabank', auth: true, level: 3 },
            },
            {
              path: '/main-payment-operators/main-transfers/bank-transfers',
              name: 'bank_transfers',
              component: BankTransfers,
              meta: { title: 'Transferencias', auth: true, level: 3 },
            },
          ],
        },
        {
          path: '/main-payment-operators/main-conciliations',
          name: 'main_conciliations',
          component: MainContent,
          meta: {
            title: 'Conciliaciones', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'payment-method', level: 2,
          },
          children: [
            {
              path: '/main-payment-operators/main-conciliations/conciliations',
              name: 'conciliations',
              component: Conciliations,
              meta: { title: 'Conciliaciones', auth: true, level: 3 },
            },
            {
              path: '/main-payment-operators/main-conciliations/shipping-conciliation',
              name: 'shipping-conciliation',
              component: ReaendedConciliations,
              meta: { title: 'Envío de Conciliaciones', auth: true, level: 3 },
            },
          ],
        },
        {
          path: '/main-payment-operators/main-discounts',
          name: 'main_discounts',
          component: MainContent,
          meta: {
            title: 'Descuentos', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'taxes-icon', level: 2,
          },
          children: [
            {
              path: '/main-payment-operators/main-discounts/discounts-history',
              name: 'discounts_history',
              component: DiscountsHistory,
              meta: { title: 'Historial de descuentos', auth: true, level: 3 },
            },
            {
              path: '/main-payment-operators/main-discounts/discounts-main-closed',
              name: 'discounts_closed',
              component: DiscountsClosed,
              meta: { title: 'Agendar descuentos (cerrados)', auth: true, level: 3 },
            },
            {
              path: '/main-payment-operators/main-discounts/discounts-main-approved',
              name: 'discounts_approved',
              component: DiscountsApproved,
              meta: { title: 'Agendar descuentos (aprobados)', auth: true, level: 3 },
            },
          ],
        },
      ],
    },
    {
      path: '/main-reimburse-users',
      name: 'main_reimburse_users',
      component: FullLayout,
      meta: {
        title: 'Reembolsos Usuarios', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'group', level: 1,
      },
      children: [
        {
          path: '/main-reimburse-users/main-reimburse',
          name: 'main_reimburse',
          component: MainContent,
          meta: {
            title: 'Reembolsos Usuarios', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'commissions-icon', level: 2,
          },
          children: [
            {
              path: '/main-reimburse-users/main-reimburse/complaints-report',
              name: 'complaints_report',
              component: ComplaintsReport,
              meta: {
                title: 'Reclamos', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-reimburse-users/main-reimburse/devolution-coupons',
              name: 'devolution_coupons',
              component: DevolutionCoupons,
              meta: {
                title: 'Códigos de reembolso', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
          ],
        },
        {
          path: '/main-reimburse-users/f-cancellations',
          name: 'f_cancellations',
          component: MainContent,
          meta: {
            title: 'F. Cancelaciones', auth: true, icon: 'cancel', level: 2,
          },
          children: [
            {
              path: '/main-reimburse-users/f-cancellations/general-view',
              name: 'general_view',
              component: GeneralView,
              meta: {
                title: 'Vista General', auth: true, level: 3,
              },
            },
            {
              path: '/main-reimburse-users/f-cancellations/paypal-view',
              name: 'paypal_view',
              component: PaypalView,
              meta: {
                title: 'Paypal', auth: true, level: 3,
              },
            },
            {
              path: '/main-reimburse-users/f-cancellations/bank-view',
              name: 'bank_view',
              component: BankView,
              meta: {
                title: 'Banco', auth: true, level: 3,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/main-control',
      name: 'main_control',
      component: FullLayout,
      meta: {
        title: 'Control', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'analysis', level: 1,
      },
      children: [
        {
          path: '/main-control/main-analytics',
          name: 'main_analytics',
          component: MainContent,
          meta: {
            title: 'Análisis', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'invoice', level: 2,
          },
          children: [
            {
              path: '/main-control/main-analytics/sales',
              name: 'sales',
              component: Sales,
              meta: {
                title: 'Ventas', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-control/main-analytics/tbk-transactions',
              name: 'tbk_transactions',
              component: TbkTransactions,
              meta: {
                title: 'Conc. TBK', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-control/main-analytics/automatic-cancellations',
              name: 'automatic_cancellations',
              component: AutomaticCancellations,
              meta: {
                title: 'Canc. ventas automaticas', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-control/main-analytics/insurance-report',
              name: 'insurance_report',
              component: InsuranceReport,
              meta: {
                title: 'Seguros Axa', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
          ],
        },
        {
          path: '/main-control/main-master',
          name: 'main_master',
          component: MainContent,
          meta: {
            title: 'Master', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'statistics', level: 2,
          },
          children: [
            {
              path: '/main-control/main-master/master-sales',
              name: 'master_sales',
              component: MasterSales,
              meta: {
                title: 'Ventas', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-control/main-master/master-incomes',
              name: 'master_incomes',
              component: MasterIncomes,
              meta: {
                title: 'Ingresos', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
          ],
        },
        {
          path: '/main-control/main-deferred-sales',
          name: 'main_deferred_sales',
          component: MainContent,
          meta: {
            title: 'Ventas Diferidas', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'calendar-with-a-clock-rotate', level: 2,
          },
          children: [
            {
              path: '/main-control/main-deferred-sales/monthly-deferred-sales',
              name: 'monthly_deferred_sales',
              component: MonthlyDeferredSales,
              meta: {
                title: 'Mensuales', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-control/main-deferred-sales/daily-deferred-sales',
              name: 'daily_deferred_sales',
              component: DailyDeferredSales,
              meta: {
                title: 'Diarias', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
          ],
        },
        {
          path: '/main-control/main-recorrido-fee',
          name: 'main_recorrido_fee',
          component: MainContent,
          meta: {
            title: 'Recorrido Fee', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'discount-sticker-with-percentage', level: 2,
          },
          children: [
            {
              path: '/main-control/main-recorrido-fee/recorrido-fee-view',
              name: 'recorrido_fee_view',
              component: RecorridoFee,
              meta: {
                title: 'Recorrido Fee', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
          ],
        },
        {
          path: '/main-control/main-netsuite',
          name: 'main_netsuite',
          component: MainContent,
          meta: {
            title: 'Netsuite', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'netsuite', level: 2,
          },
          children: [
            {
              path: '/main-control/main-netsuite/bill-credit-view',
              name: 'bill_credit_view',
              component: BillCredit,
              meta: {
                title: 'Bill/Credit', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
          ],
        },
        {
          path: '/main-control/main-activity-history',
          name: 'main_activity_history',
          component: MainContent,
          meta: {
            title: 'Historial de actividades', auth: true, icon: 'activity-history', level: 2,
          },
          children: [
            {
              path: '/main-control/main-activity-history/activity-history-view',
              name: 'activity-history_view',
              component: ActivityHistory,
              meta: {
                title: 'Historial de actividades', auth: true, level: 3,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/main-settings',
      name: 'main_settings',
      component: FullLayout,
      meta: {
        title: 'Configuración', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'operations-icon', level: 1,
      },
      children: [
        {
          path: '/main-settings/main-setting',
          name: 'main_setting',
          component: MainContent,
          meta: {
            title: 'Configuración', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], icon: 'settings-icon', level: 2,
          },
          children: [
            {
              path: '/main-settings/main-setting/operators',
              name: 'operators',
              component: Operators,
              meta: {
                title: 'Operadores', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-settings/main-setting/groups',
              name: 'groups',
              component: Groups,
              meta: {
                title: 'Grupos', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-settings/main-setting/agencies',
              name: 'agencies',
              component: Agencies,
              meta: {
                title: 'Agencias', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-settings/main-setting/holidays',
              name: 'holidays',
              component: Holidays,
              meta: {
                title: 'Días Feriados', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
            {
              path: '/main-settings/main-setting/general-settings',
              name: 'general_settings',
              component: GeneralSettings,
              meta: {
                title: 'Configuraciones Generales', auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 3,
              },
            },
          ],
        },
        {
          path: '/main-settings/main-user-maganament',
          name: 'main_user_maganament',
          component: MainContent,
          meta: {
            title: 'Gestión de usuarios', auth: true, roles: ['admin', 'superadmin'], icon: 'group', level: 2,
          },
          children: [
            {
              path: '/main-settings/main-user-maganament/user-maganament',
              name: 'user-maganament',
              component: UserManagament,
              meta: {
                title: 'Gestión de usuarios', auth: true, roles: ['admin', 'superadmin'], level: 3,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/conciliation/:id',
      name: 'conciliation',
      component: Conciliation,
      meta: {
        auth: true, roles: ['admin', 'superadmin', 'observer', 'finance_agent'], level: 0,
      },
    },
    {
      path: '*',
      redirect: '/home',
    },
  ],
});

router.beforeEach((to, from, next) => {
  fn.changeTitle(to, from, next);
  if (to.meta.auth) {
    const validSession = !!localStorage.getItem('token') && ((Math.round(new Date().getTime() / 1000)) < store.state.expiration);
    const { role } = store.state.user;
    if (!validSession || role === undefined) {
      Vue.http.post(`${constants.BASE}/logout`, { user: { email: store.state.user.email } });
      window.localStorage.clear();
      store.commit('logout');
      next({ path: '/login' });
    }
  }
});

export default router;
