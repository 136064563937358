export default {
  get_countries() {
    const countries = [
      { key: 'CL', value: 'Chile' },
      { key: 'PE', value: 'Perú' },
      { key: 'MX', value: 'México' },
    ];
    return countries;
  },
  get_payment_types() {
    const paymentTypes = [
      { key: 'WebPay', value: 'WebPay', countries: ['CL'] },
      { key: 'PayPal', value: 'PayPal', countries: ['CL'] },
      { key: 'OneClick', value: 'OneClick', countries: ['CL'] },
      { key: 'Fintoc', value: 'Fintoc', countries: ['CL'] },
      { key: 'Payu', value: 'PayU', countries: ['PE'] },
      { key: 'Payu', value: 'Payu', countries: ['MX'] },
      { key: 'Cybersource', value: 'Cybersource', countries: ['MX'] },
      { key: 'Agencia', value: 'Agencia', countries: ['CL'] },
      { key: 'b2c', value: 'Ventas B2C', countries: ['CL'] },
    ];
    return paymentTypes;
  },
  get_payment_forms() {
    const paymentForms = [
      { key: 'Débito', value: 'Débito', agency: false },
      { key: 'Crédito', value: 'Crédito', agency: false },
      { key: 'No disponible', value: 'No Disponible', agency: true },
    ];
    return paymentForms;
  },
  get_sales_channel_mediums() {
    const salesChannelMediums = [
      { key: 'web', value: 'Web' },
      { key: 'api', value: 'Api' },
      { key: 'app', value: 'App' },
    ];
    return salesChannelMediums;
  },
  get_transbank_status() {
    const transbankStatus = [
      { key: 0, value: 'Abierto' },
      { key: 1, value: 'Cerrado' },
      { key: 2, value: 'Enviado', comment: 'Comunicado a transbank' },
      { key: 3, value: 'Resuelto', comment: 'Aceptado por transbank' },
    ];
    return transbankStatus;
  },
  get_missing_in() {
    const missingIn = [
      { key: 0, value: 'Recorrido' },
      { key: 1, value: 'Transbank' },
      { key: 2, value: 'Matched' },
    ];
    return missingIn;
  },
  get_discount_categories() {
    const discountCategories = [
      { key: 0, value: 'Descuento por reclamo', enum: 'complaint' },
      { key: 1, value: 'Terminal Las Condes', enum: 'terminal_las_condes' },
      { key: 2, value: 'Marketing', enum: 'marketing' },
      { key: 3, value: 'Correcciones', enum: 'adjustment' },
      { key: 4, value: 'Otros', enum: 'others' },
    ];
    return discountCategories;
  },
  get_account_types() {
    return [
      { key: 'current', value: 'Cuenta corriente' },
      { key: 'checking', value: 'Cuenta Vista o Cuenta RUT' },
      { key: 'savings', value: 'Cuenta de ahorro' },
      { key: 'checks', value: 'Cheques' }, // MX account type
      { key: 'deposit', value: 'Cuenta Depósitos' }, // MX account type
    ];
  },
  get_banks() {
    return [
      'BANCO DE CHILE', 'BANCO EDWARDS', 'CITIBANK', 'BANCO INTERNACIONAL',
      'BANCO ESTADO', 'SCOTIABANK', 'BANCO DESARROLLO',
      'BANCO SUD AMERICANO', 'BANCO CREDITO E INVERSIONES (BCI)',
      'CORPBANCA', 'BANCO BICE', 'BANCO SANTANDER', 'BANCO ITAU',
      'THE BANK OF TOKYO MITSUBISHI', 'ABN AMOR BANK',
      'BANCO SECURITY', 'BANCO FALABELLA', 'BANCO CONSORCIO',
      'BBVA', 'SCOTIABANK AZUL (ex BBVA)', 'COOPEUCH',
      'Santander México', 'BBVA Bancomer', 'Banorte', // MX banks
    ];
  },
  get_holiday_types() {
    const holidayTypes = [
      { key: 'Religioso', value: 'Religioso' },
      { key: 'Religioso e irrenunciable', value: 'Religioso e irrenunciable' },
      { key: 'Civil', value: 'Civil' },
      { key: 'Civil e irrenunciable', value: 'Civil e irrenunciable' },
    ];
    return holidayTypes;
  },
  TransferPolicyTypes() {
    return [
      { key: 'business_day', value: 'business_day' },
      { key: 'monthly_times', value: 'monthly_times' },
      { key: 'fixed', value: 'fixed' },
      { key: 'weekly', value: 'weekly' },
    ];
  },
  WeekDays() {
    return [
      { key: 'monday', value: 'Lunes' },
      { key: 'tuesday', value: 'Martes' },
      { key: 'wednesday', value: 'Miercoles' },
      { key: 'thursday', value: 'Jueves' },
      { key: 'friday', value: 'Viernes' },
      { key: 'saturday', value: 'Sábado' },
      { key: 'sunday', value: 'Domingo' },
    ];
  },
  ResponsibilityComplaint() {
    return [
      { key: 'true', value: 'Operador' },
      { key: 'false', value: 'Recorrido' },
      { key: 'coupon', value: 'Recorrido Cupón' },
    ];
  },
  GetRoles() {
    return [
      { display_name: 'Administrador', name: 'admin' },
      { display_name: 'Chief', name: 'chief' },
      { display_name: 'Agente Finanzas', name: 'finance_agent' },
      { display_name: 'Observer', name: 'observer' },
      { display_name: 'Superadministrador', name: 'superadmin' },
    ];
  },
  CancelationStates(view) {
    const cancelationStates = [
      { key: 'refunded', value: 'Devuelto' },
      { key: 'pending', value: 'Pendiente' },
      { key: 'processing', value: 'Procesando' },
      { key: 'rejected', value: 'Rechazado' },
      { key: 'excluded', value: 'Excluido' },
      { key: 'expired', value: 'Expirado' },
    ];
    if (view === 'general') {
      cancelationStates.splice(3, 0, { key: 'coupon_payment_form_changed', value: 'Cupon/cambio forma de pago' });
      cancelationStates.splice(4, 0, { key: 'complaint_refunded', value: 'Pagado por Reclamo' });
    }

    if (view === 'bank') {
      cancelationStates.splice(3, 0, { key: 'complaint_refunded', value: 'Pagado por reclamo' });
    }

    return cancelationStates;
  },
  TicketCancellationRefundMethods(view) {
    const refundMethods = [];

    if (view === 'paypal' || view === 'general') {
      refundMethods.splice(0, 0, { key: 'via_paypal', value: 'PayPal' });
      refundMethods.splice(1, 0, { key: 'via_payroll_paypal', value: 'Nómina paypal' });
    }

    if (view === 'bank' || view === 'general') {
      refundMethods.splice(0, 0, { key: 'via_bank_transfer', value: 'Nómina Banco' });
      refundMethods.splice(1, 0, { key: 'via_tbk_devolution', value: 'Reembolso TBK' });
      refundMethods.splice(2, 0, { key: 'via_oneclick', value: 'Reembolso OneClick' });
    }

    if (view === 'general') {
      refundMethods.splice(4, 0, { key: 'via_coupon', value: 'Cupón Reembolso' });
      refundMethods.splice(5, 0, { key: 'via_external_agency', value: 'Agencia' });
    }

    return refundMethods;
  },
  FormatDate() {
    return [
      { key: 'daily', value: 'Diario' },
      { key: 'weekly', value: 'Semanal' },
      { key: 'monthly', value: 'Mensual' },
      { key: 'quarterly', value: 'Trimestral' },
      { key: 'yearly', value: 'Anual' },
    ];
  },
  FormatType() {
    return [
      { key: 'all', value: 'Todos' },
      { key: 'bill', value: 'Bill' },
      { key: 'credit', value: 'Credit' },
      { key: 'payment', value: 'Payment' },
    ];
  },
};
